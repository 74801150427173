import * as React from "react"

import Seo from "../../components/seo"

const JobLifeSkillsPage = () => {
  return (
    <section id="job-life-skills">
      <Seo
        title="Job & Life Skills"
        description="Job skills training and social-emotional learning gives students life skills they need for a future beyond high school."
      />
      <div className="one half-pic-right">
        <div className="full-screen">
          <div className="left">
            <div className="content">
              <h2>Job & Life Skills</h2>
              <p>
                Along with a quality high-school education, it is essential to
                provide healthy skills development for life after high school.
              </p>
            </div>
          </div>
          <div className="right"></div>
        </div>
        <div className="mobile">
          <div className="content">
            <h2>Job & Life Skills</h2>
            <p>
              Along with a quality high-school education, it is essential to
              provide healthy skills development for life after high school.
            </p>
          </div>
        </div>
      </div>
      <div className="two smaller-text-center">
        <div className="container">
          <div className="content">
            <p>
              Job skills training programs help students prepare for a future
              beyond high school with work readiness skills, real-world
              learning, internships and job opportunities. All students should
              have access to career and technical education, college
              preparation, dual enrollment or workforce partnerships to
              jump-start their futures with the mastery of in-demand skills.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="three half-pic-left">
        <div className="full-screen">
          <div className="left"></div>
          <div className="right">
            <div className="content">
              <p>
                High schools must offer technical, academic and leadership
                skills through rigorous and relevant coursework conducted in
                small groups with hands-on, project-based learning.
              </p>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="content">
            <p>
              High schools must offer technical, academic and leadership skills
              through rigorous and relevant coursework conducted in small groups
              with hands-on, project-based learning.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobLifeSkillsPage
